import React from 'react'
import Navbar from './Navbar'
import './Home.css';
// import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import About from './About';
import Auth from './Auth';
import Products from './Products';
import Contact from './Contact';
import Client from './Client';
import Footer from './Footer';
import Careers from './Careers';
// import videoBg from "./videoBg.mp4"

function Home() {
  return (

    <div className='hero'>
         <div className='mask'>
    
        <Card   style={{
              
                backgroundColor:"black"
              }}>
      <Box sx={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
     
 <video
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor:"black"
              }}
              src="/vid.mp4" // Path to your video file
              type="video/mp4"
              autoPlay
              muted
              loop
              controls={false} // Hide controls (play, pause buttons, etc.)
              playsInline // Allows video to play inline on mobile devices
            />



      </Box>
      <CardContent>
        <Typography
          variant="body1"
          fontWeight="lg"
          color="#fff"
          mt={{ xs: 0, sm: 5 }}
        >
          {/* Your content here */}
        </Typography>
      </CardContent>
    </Card>
    {/* </Box> */}
         </div>
       
        <About/>
      <Auth/>
      <Products/>
      <Contact/>
      <Careers/>
      <Client/>
      <Footer/>

    </div>
  )
}

export default Home