import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Connect.css';

function Connect() {
  const notify = () => toast("Message Sent!");
  const form = useRef();
  
  // State variables for the input fields
  const [userName, setUserName] = useState("");   // Sender's name (from_name)
  const [userEmail, setUserEmail] = useState(""); // Sender's email
  const [recipientName, setRecipientName] = useState(""); // Recipient's name (to_name)
  const [message, setMessage] = useState("");     // Message content
  const [loading, setLoading] = useState(false);  // Loading state

  const sendEmail = (e) => {
    e.preventDefault();

    // Field validation
    if (!userName || !userEmail || !recipientName || !message) {
      toast.error("Please fill out all fields.");
      return;
    }

    // Email validation
    if (!/\S+@\S+\.\S+/.test(userEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    emailjs
      .sendForm(
        "service_8lh5wcr",  // Your service ID
        "template_nxcxgv6", // Your template ID
        form.current,
        "SujFyVGV_m8GgTF1w" // Your public API key
      )
      .then(
        (result) => {
          toast.success("Message Sent Successfully!"); 
          setLoading(false);
          form.current.reset(); 
          setUserName("");
          setUserEmail("");
          setRecipientName("");
          setMessage("");
        },
        (error) => {
          toast.error("Failed to Send Message!");
          setLoading(false);
        }
      );
  };


  return (
    <div className="connect">
   
      <div className="connec1">
        
          <h1>Contact Us</h1>
          
          <br></br><br></br>  <br></br><br></br>
          <p className="h">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-house-door-fill"
              viewBox="0 0 16 16"
              className="w"
            >
              <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
            </svg>
            Building No.1, 2nd floor, Perumal Koil Street, Koyembedu, 
Chennai - 600 107
Tamil Nadu, India.
          </p>
          <br></br><br></br>
          <p className="h">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-telephone-fill"
              viewBox="0 0 16 16"
              className="w"
            >
              <path
                fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
              />
            </svg>
            +91 99529 14821 / 044 - 48584268
          </p>
          <br></br><br></br>
          <p className="h">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 15 15"
              className="w"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
            admin@microleaftech.in /  microleaftech@yahoo.com
          </p>
       
      </div>
      <div className="connec2">
      <br></br>
     
      <h1 className="send">Send Message</h1>
      <br></br><br></br><br></br>
      <div className="connectinner">
    
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <br />
          <input
              type="text"
              name="from_name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter your name"
            />
          <br />
          <br />
          <label>Email</label>
          <br />
          <input
              type="email"
              name="user_email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Enter your email"
            />
          <br />
          <br />

          <label>Recipient's Name</label>
            <br />
            <input
              type="text"
              name="to_name"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              placeholder="Enter recipient's name"
            />
            <br /><br />
          <label>Message</label>
          <br />
          <textarea
              rows="6"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here"
            />
          <br />
          <br />
          <button className="btn" type="submit" disabled={loading}>
            {loading ? "Sending..." : "Submit"}
          </button>
          <ToastContainer />
        </form>
      </div>
      <br></br><br></br><br></br>
      </div>
    </div>
  );
}

export default Connect;

