import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router';
import Products from './Products';
import Markets from './Markets';
import About from './About';
import Contact from './Contact';
import Navbar from './Navbar';
import Careers from './Careers';
import Home from './Home';
import Auth from './Auth';
import Footer from './Footer';
import Client from './Client';
import Pro from './Pro';
import Connect from './Connect';
import AboutUs from './AboutUs';
import Careers1 from './Careers1';
import Navbar1 from './Navbar1';
import Products1 from './Products1';

function App() {
 
  return (
    <div className="App">
     <Navbar/>
 <Routes>
  <Route exact path="/" element={<Home/>}/>
  <Route  path="/about "element={<About/>}/>
  <Route  path="/products"element={<Products/>}/>
  <Route  path="/products1"element={<Products1/>}/>

  <Route  path="/careers"element={<Careers/>}/>
  <Route  path="/careers1"element={<Careers1/>}/>

  <Route  path="/contact"element={<Contact/>}/>
  <Route  path="/navbar"element={<Navbar/>}/>
  <Route  path="/navbar1"element={<Navbar1/>}/>

  <Route  path="/connect"element={<Connect/>}/>

  <Route  path="/markets"element={<Markets/>}/>
  <Route  path="/aboutus"element={<AboutUs/>}/>


  

1 </Routes>

    
      
    </div>
  );
}

export default App;
