// import React from "react";
// import { Link } from "react-router-dom";

// function Footer() {
//   return (
//     <div className="foo">
//       <div className="footer">
//         <div className="f">
//           <p className="h">
//             We “Microleaf Software Technologies Private Limited” are
//             acknowledged organization, are engaged as manufacturer of Biometric
//             Locker, Barcode Locker, Biometric Safes.
//           </p>
//           <a
//             href="https://www.facebook.com/search/top?q=microleaf%20software%20technologies"
//             target="_blank"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="35"
//               height="35"
//               fill="currentColor"
//               class="bi bi-facebook"
//               viewBox="0 0 16 16"
//             >
//               <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
//             </svg>
//           </a>{" "}
//           <a
//             href="https://www.instagram.com/microleaf_2015/?igsh=MnVteDdqZDAxbTd1"
//             target="_blank"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="35"
//               height="35"
//               fill="currentColor"
//               class="bi bi-instagram"
//               viewBox="0 0 16 16"
//             >
//               <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
//             </svg>
//           </a>{" "}
//           <a
//             href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A38129703&keywords=microleaf%20software%20technologies%20pvt.%20ltd.&origin=RICH_QUERY_SUGGESTION&position=2&searchId=d448c04d-5701-4446-8d3d-cf210ba0b2ef&sid=jxU&spellCorrectionEnabled=false"
//             target="_blank"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="35"
//               height="35"
//               fill="currentColor"
//               class="bi bi-linkedin"
//               viewBox="0 0 16 16"
//             >
//               <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
//             </svg>
//           </a>{" "}
//           <a href="https://www.youtube.com/@microleaf8933" target="_blank">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="35"
//               height="35"
//               fill="currentColor"
//               class="bi bi-youtube"
//               viewBox="0 0 16 16"
//             >
//               <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
//             </svg>
//           </a>{" "}
//         </div>
//         <div></div>
//         <div className="f1">
//           <h1>Useful Links</h1>
//           <br></br>

//           <div className="fo">
//           <ul>
//             <li>
//               {" "}
//               <Link to="/" className="h">
//                 Home
//               </Link>
//             </li>
//           </ul>

//           <br></br>
//           <ul>
//             <li>
//               <Link to="/products" className="h">
//                 Products
//               </Link>
//             </li>
//           </ul>
         
//           <br></br>
//           <ul>
//             <li>
//               <Link to="/about" className="h">
//                 About US
//               </Link>
//             </li>
//           </ul>
//           <br></br>
//           <ul>
//             <li>
//               <Link to="/careers" className="h">
//                 Careers
//               </Link>
//             </li>
//           </ul>
//           <br></br>
//           <ul>
//             <li>
//               <Link to="/contact" className="h">
//                 Contact
//               </Link>
//             </li>
//           </ul>
//           <br></br>
//           </div>
//         </div>
//         <div className="f2">
//           <h1>About Us</h1>
//           <br></br>
//           <div className="fo">
//           <Link className="h">Leadership</Link>
//           <br></br>

//           <Link className="h">Partners</Link>
//           <br></br>

//           <Link className="h">Careers</Link>
//           <br></br>

//           <Link className="h">Blogs</Link>
//           <br></br>
//         </div>
//         </div>
//         <div className="f3">
//           <h1>Contact US</h1>
          

//           <p className="h">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="75"
//               height="75"
//               fill="currentColor"
//               class="bi bi-house-door-fill"
//               viewBox="0 0 16 16"
//               className="w"
//             >
//               <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
//             </svg>
//             22/159A, 1st floor, 6th Main Road, Sri Ayyappa Nagar, Chinmayanagar,
//             Virugambakkam, Chennai 600092 India
//           </p>
//           <p className="h">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="25"
//               height="25"
//               fill="currentColor"
//               class="bi bi-telephone-fill"
//               viewBox="0 0 16 16"
//               className="w"
//             >
//               <path
//                 fill-rule="evenodd"
//                 d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
//               />
//             </svg>
//             +91 044 48584268
//           </p>
//           <p className="h">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="25"
//               height="25"
//               fill="currentColor"
//               class="bi bi-envelope"
//               viewBox="0 0 15 15"
//               className="w"
//             >
//               <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
//             </svg>
//             Contactus@microleaftech.com
//           </p>
//         </div>
//       </div>
//       <hr></hr>
//       <p>Copyrights 2019 MICROLEAF.All rights reserved.</p>
//     </div>
//   );
// }

// export default Footer;
import React from "react";
import { Link } from "react-router-dom";
import './Footer.css';



function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-section">
          <p className="footer-description">
            We “Microleaf Software Technologies Private Limited” are
            acknowledged organization, are engaged as manufacturer of Biometric
            Locker, Barcode Locker, Biometric Safes.
          </p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/search/top?q=microleaf%20software%20technologies"
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/microleaf_2015/?igsh=MnVteDdqZDAxbTd1"
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A38129703&keywords=microleaf%20software%20technologies%20pvt.%20ltd.&origin=RICH_QUERY_SUGGESTION&position=2&searchId=d448c04d-5701-4446-8d3d-cf210ba0b2ef&sid=jxU&spellCorrectionEnabled=false" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </a>
            <a href="https://www.youtube.com/@microleaf8933" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                <path d="M8.051 1.999h-.102C6.803 1.986 3.532 1.95 2.221 2.433 1.329 2.77.586 3.512.254 4.404c-.502 1.378-.502 4.256-.502 4.256s0 2.879.502 4.257c.332.891 1.075 1.633 1.967 1.97 1.31.482 4.582.447 5.728.434h.102c1.146.013 4.418.048 5.728-.434.892-.337 1.635-1.079 1.967-1.97.502-1.378.502-4.257.502-4.257s0-2.878-.502-4.256c-.332-.892-1.075-1.634-1.967-1.971-1.31-.482-4.582-.447-5.728-.434zm-1.345 4.64 3.977 2.319-3.977 2.318V6.639z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h4>QUICK LINKS</h4>
          <ul>
            <li>
              <Link to="/aboutus">About</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            
            <li>
              <Link to="/careers1">Careers</Link>
            </li>
            <li>
              <Link to="/connect">Contact</Link>
            </li>
            
          </ul>
        </div>
        <div className="footer-section">
          <h4>CONTACT US</h4>
          <p>
          Building No.1 , 2nd floor, Perumal Koil Street , Koyembedu , Chennai. 
<br/>

 Pincode : 600 107

           
            <br />
            Phone: +91 99529 14821 / 044 - 48584268
            <br />
            Email: microleaftech@yahoo.com /
            <br/>
             admin@microleaftech.in
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
