import React from 'react'

function Markets() {
  return (
    <div>
      <h1>Markets</h1>
    </div>
  )
}

export default Markets