import "./Client.css"
import React from 'react'
import 'animate.css';
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Client() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="back" >
        <h1 className="produc">
        Our Satisfied Clients
        </h1>

        <div className="skill">
    
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                autoPlay={true} // Enable autoplay
                autoPlaySpeed={2000}
                transitionDuration={500}
                
              >
                <div className="item">
                  <img src="https://www.supersaravanastores.com/wp-content/uploads/2017/10/SS-Logo.svg" alt="js" />
                 
                </div>
                <div className="item">
                  <img src="https://bsmedia.business-standard.com/_media/bs/img/article/2016-12/07/full/1481086994-9155.jpg?im=FeatureCrop,size=(826,465)" alt="html" />
                  
                </div>
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF_oh3lEE1kNDQFV6LhvLchu3ZVA63hnAWPD4_fpwywQ&s" alt="css" />
                 
                </div>
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7BHr7norj9Hjp4q8dhhYjhXta8Pq2GqPMrA&s" alt="react" />
               
                </div>
                <div className="item">
                  <img src="https://mir-s3-cdn-cf.behance.net/projects/404/20ad71135254257.Y3JvcCwyMjUwLDE3NTksMCwyNDU.jpg" alt="mongodb" />
                 
                </div>
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX89jPZgpsSrx23OaUEZdl8LmyOfPkMOSn3xVWJ-7p&s" alt="sql" />
                 
                </div>
                <div className="item">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Yokohama_%28Unternehmen%29_logo.svg/1280px-Yokohama_%28Unternehmen%29_logo.svg.png" alt="node" />
                
                </div>
                <div className="item">
                  <img src="https://www.shutterstock.com/image-vector/samsung-logo-icon-south-korean-260nw-2269709285.jpg" alt="express" />
                </div>
               
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIZ-ClEEz9GGEC_2t_qM93QMLfcq1q10ccxbm2e8YQaw&s" alt="express" />
                
                </div>
                <div className="item">
                  <img src="https://e7.pngegg.com/pngimages/793/928/png-clipart-seal-of-tamil-nadu-government-of-tamil-nadu-logo-dating-application-form-for-women-company-text.png" alt="express" />
               
                </div>
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe9KEThjJoi8rPYhky2nSILMj_0pdcM6asaAgQjuuQ&s" alt="express" />
               
                </div>
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzK9anrWBbnahFT8qwCYc6HJ5g9beGQ-segEdl4dWS&s" alt="express" />
                 
                </div>
                <div className="item">
                  <img src="https://download.logo.wine/logo/Indian_Space_Research_Organisation/Indian_Space_Research_Organisation-Logo.wine.png" alt="express" />
                 
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <hr></hr> */}
    </div>
        {/* <div>
        <div>
          <img src="https://www.supersaravanastores.com/wp-content/uploads/2017/10/SS-Logo.svg" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
        <div>
          <img src="#" className='imag' />
        </div>
      </div> */}
      </div>


   
  )
}

export default Client