import React from "react";
import './About.css';

function Contact() {
  return (
    <div className="cont">
      <div className="con">
        <div className="co">
          <h2 className="m">MICROLEAF TECHNOLOGIES AND LIMITED</h2>
          <div className="quote-container">
    
    </div>
          <i>
          <h3 className="pro">
            We are acknowledged organization, are engaged as manufacturer of Biometric
            Locker, Barcode Locker, Biometric Safes. It was established in the
            year 2015 at Chennai, Tamil Nadu. These products are known for their
            most far-fetched quality and astonishing finishing at the reasonable
            cost in the stimulate time period.
          </h3>
          </i>
         
        </div>
      </div>
    </div>
  );
}

export default Contact;
