import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    }
     else 
    {
      setColor(false);
    }
  };
window.addEventListener("scroll",changeColor)
  return (
    <div  className="header">

      <Link to="/">
     
        <img src="/im.png" />
        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl3hVs0r0obtVPhsDdGKnizcaEzo7IeEUjw52MtLMp3d1DOiUARSTA1T8OJq3rryTOhQ&usqp=CAU" /> */}
        {/* <h1>Microleaf</h1> */}
      </Link>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/" >Home</Link>
        </li>
        <li>
        <Link to="/products1">Products</Link>
        </li>
      
        <li>
          <Link to="/aboutus">About Us</Link>
        </li>
        <li>
          <Link to="/careers1">Careers</Link>
        </li>
        <li>
          <Link to="/connect">Contact Us</Link>
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "white" }} />
        ) : (
          <FaBars size={20} style={{ color: "black" }} />
        )}
      </div>
    </div>
  );
}

export default Navbar;
