import React from "react";
import "./Auth.css";


function Auth() {
  return (
    <div className="bo">
   
      <div className="box">
        <div className="box1">
       <br></br>
          <div className="i">
            <div className="icon">
              <svg
              className="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="green"
                class="bi bi-arrow-repeat"
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                />
              </svg>
              <br></br>
            </div>
          </div>


          <h1 className="h1">Inspired</h1>
         
          <h3 className="h">
            Plans require action or they are just words we work with you to
            implement your strategic plans.
          </h3>
        </div>
        <div className="box2">
          <br></br>
          <div className="i">
            <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="green" class="bi bi-card-list" viewBox="0 0 16 16" className="svg">
  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
  <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
  </svg>
  <br></br> 

            </div>
          </div>
          <h1 className="h1">Different</h1>
          <h3 className="h">
            Goals without a plan are just a dream our team of industry experts
            will work with you to develop.
          </h3>
        </div>
        <div className="box3">
       <br></br>

          <div className="i">
            <div className="icon">
              <svg
              className="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="green"
                class="bi bi-arrow-repeat"
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                />
              </svg>
  <br></br> 

            </div>
          </div>

          <h1 className="h1">Trusted</h1>
          <h3 className="h">
            Great financial advice starts with an understanding of your personal
            and lifestyle goals.
          </h3>
        </div>
        <br></br>
       

      </div>
    </div>
  );
}

export default Auth;
