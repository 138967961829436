import React from 'react'
import './About.css';

function About() {
  return (
    <div className='abo'>
    <div className='about'>
      <h1 className='a'>
     About company
      </h1>
      <h4 >
      MICROLEAF is an ambitious paradise for the embedded technologists to implement the creativity & innovation. We primarily deal with digitally customized smart lockers. We pride ourselves as the one of the first entrants in developing Digital Locker Supplier, and the first automatic multi-door locker system provider in India..
      </h4>
      
    </div>
    <div className='about2'>

<div  className='img'>
<img src="https://tiimg.tistatic.com/fp/1/006/998/microleaf-apartment-building-otp-rfid-biometric-locker-237.jpg" />
</div>
<br></br>

{/* <div className='im'>
<div className='img1' >
  <img src="https://www.synolock.com/wp-content/uploads/2022/09/contactless-locks.png" alt=""/>
</div>
<div>
<div  className='img2'>
<img src="https://static.vecteezy.com/system/resources/thumbnails/010/058/070/small/fingerprint-scan-provides-access-with-biometrics-identification-technology-security-and-identification-concept-photo.jpg"/>
</div>

</div> 
</div>*/}
</div>

    </div>
  )
}

export default About